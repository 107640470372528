"use client";

import { useEffect } from "react";
import {initializeAnalytics} from "@/lib/firestore";

export default function AnalyticsInitializer() {
    useEffect(() => {
        initializeAnalytics().then((analytics) => {
            if (analytics) {
                console.log("Firebase Analytics initialized");
            } else {
                console.log("Analytics not supported in this environment");
            }
        });
    }, []);

    return null;
}
